<template>
  <div class="col-12 my-1 px-0">
    <button
      class="btn py-3 w-100 px-5 bg-primary d-flex flex-wrap justify-content-between align-items-center text-white"
      @click="isOpen = !isOpen"
    >
      <div class="col-12 col-md-10 text-left">{{ title }}</div>
      <img
        src="/images/icons/common/card-arrow.svg"
        alt="toggle"
        :class="isOpen && 'active'"
        class="col-12 col-md-2 icon mt-3 mt-md-0"
      />
    </button>
    <div v-if="isOpen"><slot /></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.active {
  transform: rotate(180deg);
}
.icon {
  height: 24px;
}
</style>
