<template>
  <main class="main" v-if="hasData">
    <!-- <GoogleMap /> -->

    <div id="mapContainer">
      <div v-if="groupedMarkers.length > 0" class="section-map-legend">
        <div class="item">
          <div class="title text-white">Our locations:</div>
        </div>
        <div class="item">
          <ul>
            <li v-for="type in groupedMarkers" class="location">
              <div v-if="type.locations.length > 0" class="item-legend">
                <span class="icon">
                  <b-img
                    v-if="type.type.locationTypeMarker"
                    :src="type.type.locationTypeMarker"
                    fluid
                  ></b-img>
                </span>
                <span class="label">{{ type.type.name }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section-map-container">
        <GmapMap
          :center="{ lat: 10, lng: 10 }"
          :zoom="zoom"
          map-type-id="roadmap"
          style="width: 100%; height: 650px"
          ref="mapRef"
          :options="options"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          ></gmap-info-window>
          <gmap-marker
            v-for="(m, i) in markers"
            :position="m.position"
            :clickable="true"
            :icon="m.icon"
            @click="toggleInfoWindow(m, i)"
          ></gmap-marker>
          <!--      @click="center = m.position"-->
          <!--        @click="toggleInfoWindow(m, i)"-->
        </GmapMap>
      </div>
    </div>

    <b-container fluid="xl">
      <section-title class="my-5">{{
          pageData.acf.section_1_content.title
      }}</section-title>
      <divider />
      <emergency-service
        :section-content="pageData.acf.section_2_content"
        button
        v-if="pageData.acf.section_2_content"
      />
      <divider />
    </b-container>

    <b-container fluid="xl">
      <p
        class="my-5"
        v-html="pageData.acf.section_3_content.intro"
        v-if="pageData.acf && pageData.acf.section_3_content && pageData.acf.section_3_content.intro"
      ></p>
      <div class="row justify-content-center">
        <div
          class="
            col-12 col-md-6 col-xl-4
            d-flex
            flex-column
            justify-content-center
            mt-5 mt-xl-0
          "
          v-for="card in serviceCards"
          :key="card.title"
        >
          <img :src="card.icon" class="icon" />
          <h3
            class="text-primary text-bold my-5 text-center service-card__title"
            v-html="card.title"
            v-if="card.title"
          ></h3>
          <p class="text-small text-center" v-if="card.description">{{ card.description }}</p>
          <div class="service__divider" />
        </div>
      </div>
    </b-container>

    <b-container fluid="xl" class="px-0">
      <div id="locations">
        <div v-if="groupedMarkers.length > 0" class="locations">
          <div v-for="type in groupedMarkers" class="locations-type">
            <!-- Custom view based on locations type (if show by amount, change it to type.locations.length === 1) -->

            <div
              class="accordion location-items location-type-other"
              role="tablist"
              v-if="
                type.locations.length > 0 &&
                type.type.slug != 'office' &&
                type.type.slug != 'hq' &&
                type.type.slug != 'training'
              "
            >
              <h3 class="h3 text-center text-primary">
                {{ pageData.acf.section_5_content.title }}
              </h3>
              <b-card
                bg-variant="primary"
                text-variant="white"
                no-body
                v-for="(location, index) in type.locations"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="'accordion-' + location.data.id"
                    variant="primary"
                    class="pl-4"
                  >
                    <div class="title text-center">
                      <span class="location-name">{{
                        location.data.locationName
                      }}</span>
                      ,
                      <span class="location-title">{{
                        location.data.locationTitle
                      }}</span>
                    </div>
                    <i
                      class="ico-arrow"
                      v-bind:class="{ rotate: rotatedArrow }"
                      aria-hidden="true"
                    ></i>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + location.data.id"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body variant="primary" class="p-0">
                    <div class="card-image">
                      <b-img
                        v-if="location.data.image"
                        :src="location.data.image"
                        fluid
                      ></b-img>
                    </div>

                    <b-card-text>
                      <div class="title text-center">
                        <span class="location-name">{{
                          location.data.locationName
                        }}</span>
                        ,
                        <span class="location-title">{{
                          location.data.locationTitle
                        }}</span>
                      </div>
                      <div class="contact">
                        <p class="item" v-html="location.data.street"></p>
                        <p class="item" v-html="location.data.phone"></p>
                      </div>
                    </b-card-text>
                    <a
                      href="#"
                      class="btn btn-primary btn-block text-left btn-marker"
                      @click="gotoMarker(location, index)"
                    >
                      <!--                      @click="gotoMarker(location, index)"-->
                      <!--                      @click="toggleInfoWindow(location.markers, index)"-->
                      <img :src="location.data.addressIcon" class="marker" />
                      <div class="label">see the location on the map</div>
                    </a>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-container>

    <b-container fluid="xl" class="mt-3">
      <p v-html="pageData.acf.section_5_content.description" v-if="pageData.acf.section_5_content.description"></p>
      <h6 class="text-primary text-left text-bold pt-2 pb-4">
        {{ pageData.acf.section_5_content.downloads_label }}
      </h6>
      <div class="row justify-content-center">
        <div
          class="col-12 col-md-4 pb-4 pl-5"
          v-for="file in pageData.acf.section_5_content.bulletin_years"
        >
          <product-wave
            style="min-height: 257px; font-style: italic;"
            :attachments="file.files"
            :cardTitle="file.year"
          />
        </div>

        <router-link
          class="
            btn btn-primary
            p-3
            text-small text-uppercase
            col-12
            text-bold
            redirect-btn
            mt-5
            mb-2
          "
          :to="
            '/support/'
          "
          v-if="pageData.acf &&
          pageData.acf.section_5_content &&
          pageData.acf.section_5_content.service_button &&
          pageData.acf.section_5_content.service_button.service_page &&
          pageData.acf.section_5_content.service_button.service_page.ID &&
          pageData.acf.section_5_content.service_button.anchor"
        >
          {{ pageData.acf.section_5_content.service_button.anchor }}
        </router-link>
      </div>
      <divider class="my-5" />
    </b-container>

    <b-container fluid="xl" class="mt-5" v-if="pageData.acf">
      <h3 class="text-primary text-bold text-center">
        {{ pageData.acf.section_6_content.title }}
      </h3>
      <h6 class="text-primary text-center text-bold py-4">
        {{ pageData.acf.section_6_content.subtitle }}
      </h6>
      <div v-html="pageData.acf.section_6_content.intro"></div>
    </b-container>

    <b-container fluid="xl" class="px-0">
      <div id="locations">
        <div v-if="groupedMarkers.length > 0" class="locations">
          <div v-for="type in groupedMarkers" class="locations-type">
            <!-- Custom view based on locations type (if show by amount, change it to type.locations.length === 1) -->

            <div
              class="accordion location-items location-type-other"
              role="tablist"
              v-if="
                type.locations.length > 0 &&
                type.type.slug != 'office' &&
                type.type.slug != 'hq' &&
                type.type.slug != 'service'
              "
            >
              <b-card
                bg-variant="primary"
                text-variant="white"
                no-body
                v-for="(location, index) in type.locations"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="'accordion-' + location.data.id"
                    variant="primary"
                    class="pl-4"
                  >
                    <div class="title text-center">
                      <span class="location-name">{{
                        location.data.locationName
                      }}</span>
                      ,
                      <span class="location-title">{{
                        location.data.locationTitle
                      }}</span>
                    </div>
                    <i
                      class="ico-arrow"
                      v-bind:class="{ rotate: rotatedArrow }"
                      aria-hidden="true"
                    ></i>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + location.data.id"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body variant="primary" class="p-0">
                    <div class="card-image">
                      <b-img
                        v-if="location.data.image"
                        :src="location.data.image"
                        fluid
                      ></b-img>
                    </div>

                    <b-card-text>
                      <div class="title text-center">
                        <span class="location-name" v-if="location.data.locationName">{{
                          location.data.locationName
                        }}</span>
                        ,
                        <span class="location-title" v-if="location.data.locationTitle">{{
                          location.data.locationTitle
                        }}</span>
                      </div>
                      <div class="contact">
                        <p class="item" v-html="location.data.street" v-if="location.data.street"></p>
                        <p class="item" v-html="location.data.phone" v-if="location.data.phone"></p>
                      </div>
                    </b-card-text>
                    <a
                      href="#"
                      class="btn btn-primary btn-block text-left btn-marker"
                      @click="gotoMarker(location, index)"
                    >
                      <!--                      @click="gotoMarker(location, index)"-->
                      <!--                      @click="toggleInfoWindow(location.markers, index)"-->
                      <img :src="location.data.addressIcon" class="marker" />
                      <div class="label">see the location on the map</div>
                    </a>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-5" v-if="pageData.acf">
        <div
          class="
            col-12 col-md-8
            text-primary
            d-flex
            align-items-center
            text-bold
          "
          v-if="pageData.acf.section_6_content.downloads_label"
        >
          {{ pageData.acf.section_6_content.downloads_label }}
        </div>
        <div class="col-12 col-md-4">
          <product-wave :attachments="pageData.acf.section_6_content.files" />
        </div>
      </div>
      <a
        class="
          btn btn-primary
          p-3
          text-small text-uppercase
          col-12
          text-bold
          redirect-btn
          mt-5
        "
        :href="pageData.acf.section_6_content.contact_button.url"
        v-if="pageData.acf.section_6_content.contact_button.url"
      >
        {{ pageData.acf.section_6_content.contact_button.anchor }}
      </a>
      <router-link
        class="
          btn
          bg-white
          p-3
          text-small text-primary text-uppercase
          col-12
          text-bold
          redirect-btn
          mt-5
        "
        :to="
          '/trainings/' +
          pageData.acf.section_6_content.training_button.training_page.ID
        "
        v-if="pageData.acf.section_6_content.training_button.training_page"
        style="margin-bottom: -20px"
      >
        {{ pageData.acf.section_6_content.training_button.anchor }}
      </router-link>
    </b-container>

    <div style="background: #f1f1f1">
      <b-container fluid="xl">
        <divider />
        <h3 class="my-5 text-center text-primary text-bold" v-if="pageData.acf.section_7_content.title">
          {{ pageData.acf.section_7_content.title }}
        </h3>
        <div class="row">
          <template v-for="part in pageData.acf.section_7_content.spare_parts">
            <div class="col-12 col-md-8">
              <p class="m-0 text-bold parts-text">{{ part.title }}</p>
              <p class="parts-text" v-html="part.description"></p>
            </div>

            <div class="col-12 col-md-4 pl-5">
              <product-wave :attachments="part.files" />
            </div>
          </template>

          <a
            class="
              btn btn-primary
              p-3
              text-small text-uppercase
              col-12
              text-bold
              redirect-btn
              my-5
            "
            :href="pageData.acf.section_7_content.spare_parts_button.url"
            v-if="pageData.acf.section_7_content.spare_parts_button.url"
          >
            {{ pageData.acf.section_7_content.spare_parts_button.anchor }}
          </a>
        </div>
      </b-container>
    </div>
  </main>
</template>

<script>
import axios from "@/axios";
import Divider from "../components/Divider.vue";
import Dropdown from "../components/Dropdown.vue";
import EmergencyService from "../components/EmergencyService.vue";
import ProductWave from "../components/ProductWave.vue";
import SectionTitle from "../components/SectionTitle.vue";
// import GoogleMap from "/src/components/GoogleMapSupport.vue";

export default {
  components: {
    SectionTitle,
    EmergencyService,
    Divider,
    ProductWave,
    Dropdown,
  },
  data: function () {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentPageSlug: "support",
      hasData: false,
      pageData: [],

      serviceCards: [
        {
          icon: "/images/checklist.svg",
          title: "Preventing failure and reducing costs",
          description:
            "In case of technical updates, we distribute service bulletins to help you to best operate the MarFlex pump system.",
        },
        {
          icon: "/images/technical-support.svg",
          title: "MarFlex's service agreement",
          description:
            "In case of technical updates, we distribute service bulletins to help you to best operate the MarFlex pump system.",
        },
        {
          icon: "/images/magazine.svg",
          title: "Service <br> bulletins",
          description:
            "In case of technical updates, we distribute service bulletins to help you to best operate the MarFlex pump system.",
        },
      ],

      backendResourceDomain: "",
      dataFetched: false,
      dataLocationFetched: false,
      markers: null,
      groupedLocations: null,
      locationTypes: null,
      markersTest: null,
      locationTypesTest: null,
      center: { lat: 44.464, lng: 8.862 },
      place: null,
      rotatedArrow: false,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      places: [],
      groupedMarkers: [],
      zoom: 2.9,
      options: {
        minZoom: 2,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        // gestureHandling: 'none',
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
      },
    };
  },
  metaInfo() {
    if (this.hasData && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  mounted() {
    this.fetchAllData();
    // this.geolocate();
    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   map.panTo({ lat: 41.8, lng: 9.4 });
    // });
  },
  async created() {
    this.getPageDataBySlug();
  },
  methods: {
    async fetchAllData() {
      const locationTypesRequest = axios.get("map/location_types");
      const locationsRequest = axios.get("map/locations-services");

      let groupedMarkers = [];
      await this.axios.all([locationTypesRequest, locationsRequest]).then(
        this.axios.spread((resLocationTypes, resLocations) => {
          var vm = this;
          this.locationTypes = resLocationTypes.data;
          this.locationTypes.forEach(function (entry) {
            if (entry.name == "Training" || entry.name == "Service") {
              entry.typeImageSrc = entry.acf.marker_icon.url;
              entry.typeName = entry.name;
              groupedMarkers.push({
                type: {
                  id: entry.term_id,
                  name: entry.name,
                  slug: entry.slug,
                  locationTypeMarker:
                    vm.backendResourceDomain + entry.acf.marker_icon.url,
                },
                locations: [],
              });
            }
          });
          this.groupedMarkers = groupedMarkers;
          this.markers = resLocations.data.map((x) => x.acf);
          this.markers.forEach(function (entry) {
            // if (
            //   entry.name == "Training" ||
            //   entry.name == "Service" ||
            //   entry.typeName == "Training" ||
            //   entry.typeName == "Service"
            // ) {
            resLocationTypes.data.forEach(function (type) {
              if (type.term_id == entry.type) {
                entry.icon =
                  vm.backendResourceDomain + type.acf.marker_icon.url;
                entry.addressIcon =
                  vm.backendResourceDomain + type.acf.address_icon.url;
                entry.typeName = type.name;
              }
            });
            entry.image = entry.image
              ? vm.backendResourceDomain + entry.image
              : null;
            entry.infoText =
              "<h4 style='color: black; font-size: 0.9rem; margin-bottom: 0;'>" +
              entry.location_name +
              ", " +
              entry.location_title +
              "</h4>";
            entry.infoDescription = entry.location_name;
            entry.position = {
              lat: entry.addres.lat,
              lng: entry.addres.lng,
            };
            // }
          });

          this.groupedMarkers.forEach(function (entry) {
            resLocations.data.forEach(function (location) {
              if (location.acf.type == entry.type.id) {
                // entry.locations.push({ ulica: location.acf.location_name });
                entry.locations.push({
                  title: location.post_title,
                  data: {
                    id: location.ID,
                    locationName: location.acf.location_name,
                    locationTitle: location.acf.location_title,
                    typeName: location.acf.typeName,
                    street: location.acf.full_address_section,
                    phone: location.acf.full_phone_section,
                    icon: location.acf.icon,
                    addressIcon: location.acf.addressIcon,
                    image: location.acf.image ? location.acf.image : null,
                    website: location.acf.website,
                  },
                  markers: {
                    lat: location.acf.position.lat,
                    lng: location.acf.position.lng,
                  },
                });
              }
            });
          });
        })
      );
    },
    setPlace(place) {
      this.place = place;
    },
    setDescription(infoDescription) {
      this.description = infoDescription;
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(marker.position);
      });
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    gotoMarker(location, idx) {
      this.infoWindowPos = location.markers;
      this.infoOptions.content = location.data.locationName;
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(location.markers);
      });
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
    },
    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          },
        });
        this.place = null;
      }
    },

    getPageDataBySlug() {
      this.axios
        .get(this.backendUrl + "custom-pages/" + this.currentPageSlug)
        .then((response) => {
          this.pageData = response.data;
          this.hasData = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.icon {
  height: 50px;
}
.service {
  &__divider {
    width: 50%;
    background: black;
    height: 1px;
    margin: 0 auto;
  }
  &-card__title {
    line-height: 48px;
  }
  &-location {
    background: $blue-30;
    &__icon {
      width: 80px;
      margin-top: -60px;
    }
  }
}
.parts-text {
  line-height: 48px;
  font-size: 24px;
}
</style>



<style lang="scss" scoped>
#mapContainer {
  position: relative;
  height: 650px;
  .section-map-container {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .section-map-legend {
    position: absolute;
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    max-width: 100%;
    width: 250px;
    bottom: 5%;
    right: calc(50% - 125px);
    align-items: center;
    .item {
      flex: 0 0 50%;
      width: 50%;
      max-width: 150px;
      font-size: 1rem;
      ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
      }
      .location {
        // background-color: #0c2552;
        background-color: #1f50a9;
        margin: 1px 0;
        .item-legend {
          height: 55px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          .icon {
            margin-right: 10px;
          }
          .label {
            color: #ffffff;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .section-map-legend {
      flex-direction: column;
      max-width: 270px;
      right: 4%;
      .item {
        max-width: 122px;
        width: 100%;
        position: relative;
        .location {
          max-width: 177px;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .section-map-legend {
      .item {
        max-width: 100%;
        position: relative;
        font-size: 1.6rem;
      }
    }
  }
}

/* Cards */
.xs-px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.container-xl {
  padding-right: 0;
  padding-left: 0;
}
@media only screen and (min-width: 768px) {
  .container-xl {
    padding-right: 15px;
    padding-left: 15px;
  }
  .xs-px-15 {
    padding-left: 0;
    padding-right: 0;
  }
}
#locations {
  .ico-arrow {
    width: 24px;
    height: 24px;
  }
  .ico-arrow:before {
    content: "";
    display: inline-block;
    background-image: url("/images/icons/common/card-arrow.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transition: transform 0.2s linear;
  }
  .locations-type {
    margin-top: 48px;
    .h3 {
      margin: 24px 0;
    }
  }
  .accordion {
    .card {
      margin: 1px 0;
    }
  }
  .card-header {
    .btn {
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .ico-arrow {
        align-self: center;
      }
      &.not-collapsed {
        .ico-arrow {
          &:before {
            transform: rotateZ(180deg);
          }
        }
      }
    }
    .location-name {
      text-transform: uppercase;
    }
  }
  .card-image {
    margin-bottom: 54px;
    padding: 0 15px;
  }
  .card-text {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    .title {
      text-align: left;
      font-size: 2.25rem;
      margin-bottom: 58px;
    }
    .contact {
      align-self: center;
      display: flex;
      flex-direction: column;
      .item {
        padding: 15px;
      }
    }
  }

  .btn-marker {
    background-color: #153163;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    .marker {
      width: 60px;
      height: auto;
      align-self: center;
      margin-bottom: 15px;
    }
    .label {
      position: relative;
      align-self: center;
      display: flex;
      flex-direction: column;
      &:after {
        content: "";
        display: block;
        background-image: url("/images/icons/common/icon-right-arrow.svg");
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        align-self: center;
        margin-top: 15px;
      }
    }
  }

  /* Google Map Infowindow */
  .info-text {
    color: #ff0000;
  }
  .gm-style-iw {
    &.gm-style-iw-c {
      background-color: #ff0000 !important;
      outline: solid 1px blue;
    }
  }
  .storemapper-iw-inner {
    color: blue !important;
    outline: solid 1px lime;
  }
  @media only screen and (min-width: 768px) {
    .accordion {
      .card-text {
        .title {
          padding: 0 15px;
        }
      }
    }
    .card-image {
      margin-bottom: 84px;
      padding: 0;
    }
    .card-header {
      .btn {
        flex-direction: row;
      }
    }
    .card-text {
      .contact {
        flex-direction: row;
        .item {
          padding: 0 50px;
        }
      }
    }
    .btn-marker {
      position: relative;
      padding: 10px 153px;
      margin-bottom: 2.25rem;
      margin-top: 100px;
      flex-direction: row;
      .marker {
        position: absolute;
        bottom: 16px;
        left: 49px;
      }
      .label {
        align-self: center;
        &:after {
          position: absolute;
          top: 6px;
          right: -63px;
          margin-top: 0;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .card-image {
      margin-bottom: 84px;
    }
    .card-text {
      padding: 0 65px;
    }
  }
}
</style>
